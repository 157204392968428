.authentication-modal-layout .sign-up {
  &__container {
    grid-template-columns: 280px;

    @include bp-md {
      grid-template-columns: 460px;
    }

    .sign-up__btn {
      margin-bottom: 120px;

      @include bp-sm {
        margin-bottom: 0px;
      }
    }
  }
}
