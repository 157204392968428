.success {
  &__text {
    width: 280px;

    @include bp-xs {
      width: 320px;
    }
  }

  &__btn {
    width: 280px;
    margin: 0;
    box-sizing: border-box;

    @include bp-xs {
      width: 320px;
    }
  }
}
