.modal-container {
  position: fixed;
  width: 100vw;
  height: calc(100vh - 125px);
  top: 125px;
  left: 0;
  z-index: 2000005;
  overflow: auto;
  background-color: var(--white);

  @include bp-sm {
    margin-top: 0;
    height: 100vh;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
}

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--white);
  opacity: 0.8;
  z-index: -1;
}

.modal {
  box-sizing: border-box;
  background-color: var(--white);
  min-width: 300px;
  opacity: 1;
  z-index: 2;
  padding: 16px;
  min-height: 100%;
  overflow: scroll;
  display: block;
  top: auto;

  @include bp-sm {
    margin: 0 auto;
    min-height: 200px;
    max-width: 534px;
    box-shadow: 0px 5px 20px rgba(12, 49, 100, 0.1);
    border: 1px solid #e4e4e3;
    border-radius: 10px;
    overflow: auto;
  }

  @include bp-md {
    height: auto;
    max-width: 732px;
  }

  @include bp-lg {
    max-width: 800px;
    padding: 30px 40px;
    justify-content: center;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
