.validate-mark {
  position: absolute;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1;
  right: 0;

  &__indent {
    right: 30px;
  }

  &__error {
    color: #bf0f1e;
  }
}

.validate-mark.rtl {
  right: auto;
  left: 0px;
}

.validate-mark__indent.rtl {
  right: auto;
  left: 30px;
}
