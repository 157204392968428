.input-content {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.input-wrapper .input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  background-color: var(--white);
  border: 1px solid var(--gray-40);
  height: var(--input-h);
  padding: var(--input-p);
  width: 100%;
  @include p4-1;
  @include transition;

  svg {
    path {
      fill: var(--gray-40);
    }
  }

  &:focus,
  &:hover:enabled {
    border-color: var(--gray-70);
  }

  &:disabled {
    cursor: not-allowed;
  }

  &::placeholder {
    color: var(--gray-40);
  }

  &__reset {
    position: absolute;
    right: 0;
  }
}
