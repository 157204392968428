// typography

@import "./mixins.scss";

@mixin p1 {
  font-size: var(--fs-1); // 12
  line-height: 120%;
}

@mixin p2 {
  font-size: var(--fs-2); // 14
  line-height: 18px;
}

@mixin p2-1 {
  font-size: var(--fs-2); // 14
  line-height: 16px;
}

@mixin p3 {
  font-size: var(--fs-3); // 15
  line-height: 16px;
}

@mixin p4-1 {
  font-size: var(--fs-4); // 16
  line-height: 24px;
}

@mixin p4 {
  font-size: var(--fs-4); // 16
  line-height: 20px;
}

@mixin p5 {
  font-size: var(--fs-5); // 18
  line-height: 24px;
}

@mixin p6 {
  font-size: var(--fs-6); // 20
  line-height: 24px;
}

@mixin p7 {
  font-size: var(--fs-7); // 24
  line-height: 32px;
}

@mixin p7-1 {
  font-size: var(--fs-7); // 24
  line-height: 28px;
  letter-spacing: -0.02em;
}

@mixin p8 {
  font-size: var(--fs-8); // 25
  line-height: 28px;
}

@mixin p9 {
  font-size: var(--fs-9); // 28px
  line-height: 32px;
}

@mixin h3-med {
  font-size: 28px;
  letter-spacing: 0.01em;
  font-weight: 500;
  font-feature-settings: "pnum" on, "lnum" on;
  font-family: var(--secondary-font);
  margin: 0;

  &::after {
    display: none;
  }

  @include bp-md {
    font-size: 30px;
  }
}

@mixin h4 {
  font-family: var(--secondary-font);
  font-weight: 600;
  margin: 0;
  font-size: 24px;
  line-height: 32px;

  &::after {
    display: none;
  }

  @include bp-md {
    font-size: 28px;
    line-height: 32px;
  }
}

.p1 {
  @include p1;
}

.p2 {
  @include p2;
}

.p2-1 {
  @include p2-1;
}

.p3 {
  @include p3;
}

.p4 {
  @include p4;
}

.p5 {
  @include p5;
}

.p6 {
  @include p6;
}

.p7 {
  @include p7;
}

.p7-1 {
  @include p7-1;
}

.p8 {
  @include p8;
}

.p9 {
  @include p9;
}

.subtitle {
  font-size: var(--fs-4); // 16
  line-height: 20px;

  @include bp-md {
    line-height: 24px;
    font-size: var(--fs-5); // 18
    width: 475px;
  }
}

.cap-2-reg {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.cap-2-reg-err {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: var(--error-color-50);
}

.cap-3-reg {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}

.h4-semi {
  @include h4;
  font-weight: 600;
}

.h3-med {
  @include h3-med;
}

.h3-med-semi {
  @include h3-med;
  font-weight: bold;
}

.h3 {
  font-style: normal;
  font-weight: bold;
  font-size: var(--fs-10); // 32
  line-height: 1;

  &::after {
    display: none;
  }
}

.h2 {
  font-weight: bold;
  font-style: normal;
  font-size: var(--fs-13);
  line-height: 1;
  font-family: var(--secondary-font);
  margin: 0;

  &::after {
    display: none;
  }
}

.h2-med {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 34px;
  margin: 0;

  &::after {
    display: none;
  }

  @include bp-md {
    font-size: 36px;
    line-height: 36px;
  }
}

.h1 {
  font-weight: 600;
  font-size: var(--fs-11); // 48
  line-height: 1;
  font-style: normal;
  margin: 0;
  font-family: var(--secondary-font);

  &::after {
    display: none;
  }

  @include bp-lg {
    width: 655px;
  }

  @include bp-xxl {
    width: 780px;
    font-size: var(--fs-12); // 80
    line-height: 1;
  }
}

.trim {
  @include trim(1);
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.second-font {
  font-family: var(--secondary-font);
}

.bold {
  font-weight: 600;
}

.blue {
  color: var(--color-primary-50);
}

.tint-link {
  display: inline-block;
  color: var(--color-primary-50);
  text-decoration: underline;
}

.text-inactive {
  color: var(--gray-40);
}
