.authentication-modal-layout {
  background: var(--modal-bg);
  border: 1px solid var(--modal-b);
  box-shadow: 0px 5px 20px var(--modal-box-shadow);

  @include bp-sm {
    background: none;
    border: none;
    box-shadow: none;
  }

  .modal {
    box-sizing: border-box;
    position: relative;
    border-radius: 0px;
    padding: 48px 24px 48px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-height: 100%;
    background: none;

    @include bp-sm {
      background: var(--modal-bg);
      border: 1px solid var(--modal-b);
      box-shadow: 0px 5px 20px var(--modal-box-shadow);
    }

    @include bp-md {
      padding: 48px;
      width: 640px;
    }

    &__close-btn {
      position: absolute;
      top: 24px;
      right: 24px;
      padding: 0;
    }
  }

  .modal-overlay {
    opacity: 0.8;
  }

  .form-container {
    display: grid;
    align-items: center;
    grid-row-gap: 16px;
    text-align: left;

    .btn {
      font-weight: normal;
      width: 100%;
      margin: 0;
    }
  }

  .form-alternative {
    text-align: center;
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .modal-link {
      text-decoration: underline;
    }
  }

  .form-caption {
    text-align: center;
    color: var(--color-secondary-90);
    margin: 8px auto 0 auto;
    font-size: 14px;
    line-height: 20px;

    a:not(.btn) {
      color: var(--color-primary-50);
      display: inline;
    }
  }

  a:not(.btn) {
    cursor: pointer;
    color: var(--color-secondary-90);
  }
}
