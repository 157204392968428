.authorization-widget {
  --modal-box-shadow: rgba(12, 49, 100, 0.07);

  color: var(--gray-100);
  font-family: var(--primary-font);
  display: inline-block;
  vertical-align: middle;

  @import "@tint_fe/ui/src/assets/scss/mixins.scss";
  @import "@tint_fe/ui/src/assets/scss/reset.scss";
  @import "@tint_fe/ui/src/assets/scss/typography.scss";
  @import "@tint_fe/ui/src/assets/scss/components/modals/modal.scss";
  @import "@tint_fe/ui/src/assets/scss/components/divider.scss";
  @import "@tint_fe/ui/src/assets/scss/components/button.scss";
  @import "@tint_fe/ui/src/assets/scss/components/inputs/index.scss";
  @import "@tint_fe/ui/src/assets/scss/components/loader.scss";

  @import "./components/index.scss";
}

.logout-btn-wrapper {
  height: 24px;
  display: inline-flex;
}
