@mixin trim($numLines: null) {
  @if $numLines != null {
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
    overflow: hidden;
  } @else {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
}

@mixin transition($property: all, $duration: 0.3s, $offset: 0s) {
  transition: $property $duration $offset ease-in-out;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin bp-xs {
  @media screen and (min-width: 376px) {
    @content;
  }
}

@mixin bp-sm {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin bp-md {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin bp-lg {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin bp-xl {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin bp-xxl {
  @media screen and (min-width: 1450px) {
    @content;
  }
}

@mixin container {
  margin: 0;
  padding: 0 16px;

  @include bp-sm {
    margin: 0 auto;
    max-width: 534px;
  }

  @include bp-md {
    max-width: 732px;
  }

  @include bp-lg {
    max-width: 960px;
  }

  @include bp-xl {
    max-width: 1158px;
  }
}
