@import "./mixins";

.input-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
  @include transition;

  &:hover:not(.input-wrapper__disabled):not(.input-wrapper__error) {
    .input {
      @include input-hover;
    }

    .rc-select__control {
      @include input-hover;
    }
  }

  &__text,
  &__textarea {
    .input {
      cursor: text;
    }
  }

  &__disabled {
    .input {
      border-color: var(--gray-40-05);
      color: var(--gray-50-05);

      &::placeholder {
        color: var(--gray-50-05);
      }
    }

    .rc-select__control {
      border-color: var(--gray-40-05);
      color: var(--gray-50-05);
    }
  }

  &__small {
    .input {
      height: var(--input-sm-h);
      padding: var(--input-sm-p);
    }

    .rc-select__control {
      height: var(--input-h);
      padding: var(--input-sm-p);
    }
  }

  &__outline {
    .input {
      background-color: transparent;
    }

    &:hover:not(.input-wrapper__disabled):not(.input-wrapper__error):not(
        .input-wrapper__focus
      ) {
      .input {
        border-bottom: 1px solid var(--gray-50);
        background-color: transparent;

        &::placeholder {
          color: var(--gray-50);
        }
      }

      .rc-select__control {
        border-bottom: 1px solid var(--gray-50);
      }
    }

    .input {
      height: var(--input-outline-h);
      padding: var(--input-outline-p);
      border: none;
      border-bottom: 1px solid var(--gray-20);
      @include p6;
    }

    .rc-select__control {
      border: none;
      border-bottom: 1px solid var(--gray-20);
      height: var(--input-outline-h);
      .rc-select__value-container {
        padding: var(--input-outline-p);
        @include p6;
      }

      .rc-select__input {
        input {
          line-height: 24px;
        }
      }
    }
  }

  &__outline.input-wrapper__with-value:not(.input-wrapper__disabled):not(
      .input-wrapper__error
    ):not(.input-wrapper__focus) {
    .input,
    .rc-select__control {
      border-color: var(--color-secondary-70);
    }
  }

  &__outline.input-wrapper__focus:not(.input-wrapper__disabled):not(
      .input-wrapper__error
    ) {
    .input,
    .rc-select__control {
      border-color: var(--color-secondary-30);
    }
  }

  &__error {
    min-height: 76px;

    .input-icon svg path {
      fill: var(--error-color-50);
    }

    .input {
      border-color: var(--error-color-50);
    }

    .rc-select__control {
      border-color: var(--error-color-50);
    }
  }

  &__with-overflow-icon-tooltip {
    .rc-select__single-value {
      @include transition;
      padding-right: 0px;
    }
  }

  &__rtl {
    text-align: right;

    .input-content__phone {
      .react-tel-input {
        .input {
          padding-left: 10px;
          padding-right: 72px;
        }

        .flag-dropdown .selected-flag {
          padding-left: 36px;
          padding-right: 6px;

          &::after {
            right: auto;
            left: 0;
          }

          .arrow {
            left: -26px;
          }
        }
      }
    }

    .prev-icon {
      right: var(--input-p-r);
      left: auto;
    }

    .next-icon {
      left: var(--input-p-l);
      right: auto;
    }
  }

  &__prev-icon {
    .input,
    .rc-select__control {
      padding-left: calc(var(--input-p-r) + 24px);
    }
  }

  &__label-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__remark-icon {
    margin-bottom: 4px;
    margin-left: 8px;

    svg {
      path {
        stroke: var(--extra-color-50);
        fill: var(--white) !important;
      }
    }
  }

  &__remark {
    color: var(--gray-60);
    font-size: var(--fs-1);
    line-height: 15px;
  }

  textarea.input {
    height: auto;
  }

  &__label {
    z-index: 0;
    margin-bottom: 4px;
    color: var(--gray-70);
    font-family: var(--primary-font);
    @include p2-1;
  }

  &__container {
    position: relative;
  }

  &__overflow-tooltip {
    position: absolute;
    right: 16px;
    z-index: 100;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      var(--white) 20%,
      var(--white) 100%
    );
    padding: 4px 8px 4px 12px;
  }

  &__overflow-tooltip-with-next {
    right: 24px;
  }
}

.input-icon {
  display: flex;
  position: absolute;
  cursor: pointer;
  z-index: 1;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: var(--gray-70);
    }
  }
}

.input-icon-card {
  display: flex;
  position: absolute;
  cursor: pointer;
  z-index: 1;

  svg {
    width: 35px;
    height: 24px;
  }
}

.prev-icon {
  left: var(--input-p-l);
}

.next-icon {
  right: var(--input-p-r);
}

.next-icon-reset {
  padding: 6px;
  right: calc(var(--input-p-r) - 6px);
}

.error-message {
  color: var(--error-color-50);
  font-size: 14px;
  line-height: 1;
  margin-top: 6px;
  animation: 0.3s ease-in-out forwards show-error;
}

@keyframes show-error {
  from {
    opacity: 0;
    margin-bottom: 0px;
  }
  to {
    margin-bottom: 6px;
    opacity: 1;
  }
}
