.my-favorite-link {
  display: inline-flex;
  align-items: center;
  color: inherit;
  text-transform: capitalize;
  font-weight: inherit;
  font-size: inherit;

  svg {
    flex-shrink: 0;
  }
}
