.overflow-value-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  z-index: -100;
  @include transition;

  &__show {
    opacity: 1;
    z-index: 100;
  }

  svg {
    circle {
      stroke: var(--gray-50);
    }
    path {
      fill: var(--gray-50);
    }
  }
}
