.forgot-password {
  &__container {
    width: 280px;

    @include bp-xs {
      width: 320px;
    }
  }

  &__alternative {
    width: 280px;

    @include bp-xs {
      width: 352px;
    }
  }
}
