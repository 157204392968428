.logout-btn {
  padding: 0 5px;
  box-sizing: border-box;
  display: inline;
  font-size: 14px;
  line-height: 18px;
  color: var(--gray-100);
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
}
