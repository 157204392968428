.logged-select {
  .login-btn {
    span {
      font-size: 14px;
      line-height: 16px;
    }

    svg {
      flex-shrink: 0;
    }
  }

  .rc-select__control {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    opacity: 0;
  }

  .rc-select__menu {
    z-index: 2400000;
    width: 150px;
    right: 0;
    padding-top: 8px;
    margin-top: 0px;
  }

  .rc-select__menu-list {
    padding-top: 6px;
    border-radius: 0px;
  }

  .rc-select__option {
    cursor: pointer;
    padding: 6px 16px;
    text-align: left;

    a,
    button {
      font-family: var(--primary-font);
      color: var(---gray-100);
      font-size: 18px;
      line-height: 28px;
      text-transform: capitalize;
      padding: 0;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .rc-select__option.profile {
    border-top: 1px solid var(--gray-20);
  }
}
