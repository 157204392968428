.google-autocomplete {
  &__content-overflow {
    .input {
      padding-right: 42px;
    }

    .overflow-value-icon {
      right: 10px;
    }
  }
}

.google-autocomplete.input-wrapper__disabled {
  .input-content {
    .input {
      background-color: var(--gray-10);
      border-color: var(--gray-40-05);
      color: var(--gray-50);

      &::placeholder {
        color: var(--gray-50);
      }
    }
  }
}
