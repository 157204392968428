.react-tel-input {
  .input {
    padding-left: 72px;

    &::placeholder {
      color: transparent;
    }
  }

  .flag-dropdown {
    background-color: transparent;
    border: none;

    .selected-flag {
      padding-left: 6px;
      padding-right: 36px;
      width: 58px;
      &:hover,
      &:focus {
        background-color: transparent;
      }

      &::after {
        --top-offset: 10px;
        content: "";
        position: absolute;
        width: 1px;
        height: calc(100% - (2 * var(--top-offset)));
        background-color: var(--gray-50);
        right: 0px;
        top: var(--top-offset);
      }

      .arrow {
        width: 8px;
        height: 8px;
        border: none;
        border-left: 2px solid var(--gray-100);
        border-bottom: 2px solid var(--gray-100);
        transform: rotate(-45deg);
        transform-origin: center;
        margin-top: -5px;
        left: 26px;
        @include transition;
      }
    }
  }

  .flag-dropdown.open {
    background-color: transparent;

    .selected-flag {
      background-color: transparent;

      .arrow {
        margin-top: -2px;
        transform: rotate(135deg);
      }
    }
  }

  .flag {
    width: 15px;
    height: 10px;
  }
  .selected-flag {
    &::after {
      height: calc(100% - 20px);
    }
  }
  .country-list {
    .country {
      padding: 6px 10px;
      margin: 0;
      line-height: 1.25;
    }
    .search {
      padding: 10px 10px 5px;
      z-index: 2;
    }
    .search-box {
      width: 100%;
      margin: 0;
      padding: 6px 8px;
      border-radius: 0;
      font-size: 1em;
      line-height: 1;
    }
    /* clears the 'x' from Internet Explorer */
    input[type="search"]::-ms-clear,
    input[type="search"]::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    /* clears the 'x' from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      display: none;
    }
  }
}
