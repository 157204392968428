.divider {
  position: relative;
  display: flex;
  align-items: center;

  &::before,
  &::after {
    content: "";
    height: 1px;
    flex-grow: 1;
    background-color: var(--gray-40);
  }

  span {
    color: var(--gray-70);
    font-size: 14px;
    line-height: 16px;
    font-family: var(--primary-font);
    flex-grow: 0;
    padding: 0 8px;
  }
}
