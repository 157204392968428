button {
  outline: none;
  background-color: transparent;
  border: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
}

button,
input,
textarea {
  font-family: var(--primary-font);
  -webkit-appearance: none;
  border-radius: 0;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-appearance: none;
}
