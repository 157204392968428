.select {
  &__preselected {
    .rc-select .rc-select__control {
      border: none;
      background-color: transparent;

      .rc-select__value-container {
        padding-left: 0;
      }

      .rc-select__single-value {
        color: var(--gray-60);
      }

      .rc-select__indicators {
        display: none;
      }
    }
  }

  .rc-select {
    width: 100%;

    &__control {
      padding: 0px;
      font-size: 20px;
      line-height: 24px;
      border-radius: 0px;
      cursor: pointer;
      height: var(--input-h);
      border: 1px solid var(--gray-40);
      color: var(--gray-100);

      &--is-focused {
        outline: none;
        box-shadow: none;
        border-color: var(--gray-65);
      }
    }

    &__value-container {
      padding: var(--input-p);
      height: 100%;
      @include p4-1;
      @include transition;

      &--has-value {
        border-color: var(--gray-100);
      }
    }

    &__single-value {
      margin: 0;
    }

    &__placeholder {
      color: var(--gray-40);
    }

    &__menu {
      z-index: 999999;
      background-color: transparent;
      box-shadow: none;
      padding-bottom: 10px;
    }

    &__menu-list {
      padding-top: 16px;
      background-color: var(--white);
      border-radius: 4px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
    }

    &__clear-indicator {
      position: absolute;
      top: 8px;
      z-index: 1;
      right: 0;
      cursor: pointer;
    }

    &__option {
      display: flex;
      flex-direction: column;
      padding: 4px 16px;

      .main-text {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: var(--color-secondary-100);
      }

      &--is-focused {
        background-color: var(--color-secondary-10);
      }

      &--is-selected {
        color: var(--color-secondary-100);
        background-color: var(--color-secondary-30);

        &:hover {
          background-color: var(--color-secondary-40);
        }
      }
    }

    &__group-heading {
      background-color: var(--white) !important;

      span {
        text-transform: none;
        font-weight: 400;
        font-family: var(--primary-font);
        color: var(--gray-100);
        font-size: 16px;
      }
    }
  }

  .win-rc-select.rc-select__menu {
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px var(--gray-50-05);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background-color: var(--gray-65);
      border-radius: 10px;
      transition: background-color 2s ease-out;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--gray-90);
    }
  }

  &__container {
    position: relative;
    display: block;
  }

  .select__option-flag {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: auto;

    .main-text {
      font-weight: normal;
      color: var(--gray-100);
    }

    img {
      align-self: flex-start;
      width: 20px;
      height: 15px;
      margin-top: 4px;
    }
  }

  .rc-select__group {
    padding-top: 0;
  }

  .rc-select__group-heading {
    padding: 8px 16px;
    background-color: var(--gray-10);
    font-weight: bold;
  }

  .circle-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    right: auto;
    width: var(--input-h);

    svg {
      width: 18px;
      max-width: 10rem;
      animation: rotate 3.6s linear infinite;
    }

    circle {
      fill: none;
      stroke: var(--gray-40);
      stroke-width: 6px;
      stroke-dasharray: 300;
      animation: outline 2s cubic-bezier(0.77, 0, 0.18, 1) infinite;
    }

    @keyframes outline {
      0% {
        stroke-dashoffset: 0;
      }

      50% {
        stroke-dashoffset: 300;
      }

      100% {
        stroke-dashoffset: 600;
      }
    }

    @keyframes rotate {
      from {
        transform: rotate(0turn);
      }

      to {
        transform: rotate(-1turn);
      }
    }
  }
}
