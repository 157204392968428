.login-btn {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .avatar {
    margin-right: 6px;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
  }

  a {
    font-size: 14px;
    line-height: 18px;
    color: var(--gray-100);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .slash {
    color: #b1b1b1;
  }
}
