.loader {
  @keyframes dotFlashing {
    0% {
      background-color: var(--color-secondary-10);
      transform: scale(1);
    }
    25% {
      background-color: var(--color-secondary-30);
    }
    50% {
      background-color: var(--color-secondary-50);
      transform: scale(1.05);
    }

    75% {
      background-color: var(--color-secondary-30);
    }
    100% {
      background-color: var(--color-secondary-10);
      transform: scale(1);
    }
  }

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: var(--color-secondary-10);
    border-radius: 50%;
    margin-left: 8px;
    animation: dotFlashing 1.4s infinite alternate;
    animation-fill-mode: both;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

.loader.dark {
  @keyframes darkDotsFlashing {
    0% {
      background-color: var(--gray-70);
      transform: scale(1);
    }
    25% {
      background-color: var(--gray-40);
    }
    50% {
      background-color: var(--white);
      transform: scale(1.05);
    }

    75% {
      background-color: var(--gray-40);
    }
    100% {
      background-color: var(--gray-70);
      transform: scale(1);
    }
  }

  span {
    background-color: var(--gray-70);
    animation: darkDotsFlashing 1.4s infinite alternate;
    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}
