.sig-in {
  &__container {
    grid-template-columns: 280px;

    @include bp-xs {
      grid-template-columns: 320px;
    }
  }

  &__fg-psw {
    text-align: center;
    text-decoration: underline;
  }

  &__form-caption {
    max-width: 326px;
    padding-bottom: 120px;
    
    @include bp-sm {
      padding-bottom: 0px;
    }
  }
}
