@mixin submit-arrow {
  transition: transform 0.15s ease-in-out;

  &::after {
    content: " \0203a";
    position: relative;
    display: inline-block;
    margin-left: 5px;
    padding-right: 5px;
    align-self: center;
    overflow: hidden;
    line-height: 10px;
    transition: transform 0.15s ease-in-out;
  }

  &:hover:not(&:disabled) {
    text-decoration: underline;
    transition: transform 0.15s ease-in-out;

    &::after {
      transform: translateX(5px);
      transition: transform 0.15s ease-in-out;
    }
  }
}

@mixin back-arrow {
  transition: transform 0.15s ease-in-out;

  &::after {
    transition: transform 0.15s ease-in-out;
    padding-left: 5px;
    margin-left: 5px;
    align-self: center;
    top: 2px;
  }

  &:hover:not(&:disabled) {
    &::after {
      transition: transform 0.15s ease-in-out;
      transform: translateX(-5px) rotate(180deg);
    }
  }
}

.btn,
.btn_override {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  height: var(--btn-h-md);
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  @include transition;
  @include p2;

  @include bp-xs {
    @include p4;
  }

  @include bp-sm {
    @include p5;
  }

  @include bp-md {
    letter-spacing: 1;
  }

  &:disabled,
  &__disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &__blue {
    background-color: var(--color-primary-50);
    border: 1px solid var(--color-primary-50);
    color: var(--white);

    &:hover:not(&:disabled) {
      background-color: var(--color-primary-60);
      border-color: var(--color-primary-60);
    }

    &:active {
      background-color: var(--color-primary-70);
      border-color: var(--color-primary-70);
    }

    .btn__spinner {
      path {
        fill: var(--white);
      }
    }
  }

  &__dark {
    background-color: var(--gray-100);
    border: 1px solid var(--gray-100);
    color: var(--white);

    &:hover:not(&:disabled) {
      background-color: var(--gray-90);
      border-color: var(--gray-90);
    }

    &:active {
      background-color: var(--gray-100);
      border-color: var(--gray-100);
    }

    .btn__spinner {
      path {
        fill: var(--white);
      }
    }
  }

  &__transparent {
    color: var(--color-primary-50);

    .btn__spinner {
      path {
        fill: var(--white);
      }
    }
  }

  &__light {
    background-color: var(--color-secondary-30);
    border: 1px solid var(--color-secondary-30);
    color: var(--gray-100);

    &:disabled {
      opacity: 0.6;
    }
  }

  &__ghost {
    background-color: transparent;
    color: var(--gray-100);

    &:hover:not(&:disabled) {
      background-color: var(--gray-100-01);
    }
  }

  &__ghost.btn__is-back-btn {
    svg path {
      stroke: var(--gray-100);
    }
  }

  &__prev-icon {
    svg {
      margin-right: 8px;
    }
  }

  &__next-icon {
    svg {
      margin-left: 8px;
    }
  }

  &__round {
    border-radius: var(--btn-br);
    text-transform: none;
  }

  &__fullsize {
    padding-left: auto;
    padding-right: auto;
    width: 100%;
  }

  &__small {
    height: var(--btn-h-sm);
    padding: 0 16px 0 12px;
    letter-spacing: 0.06em;
    @include p4-1;
  }

  &__big {
    height: var(--btn-h-bg);
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }

  &__spinner {
    path {
      transform-origin: center;
      animation: spinner 1s infinite;
    }
  }

  &__without-border {
    border-color: transparent;

    &:hover:not(&:disabled) {
      border-color: transparent;
    }
  }

  &__is-back-btn {
    flex-direction: row-reverse;
    max-width: 180px;
    width: 100%;
  }

  &__submit {
    width: 100%;

    @include bp-sm {
      width: 280px;
    }
  }

  &__show-more {
    width: 100%;
    border: 1px solid var(--gray-100) !important;
    background-color: transparent !important;
    color: var(--gray-100);
    cursor: pointer;

    @include bp-sm {
      width: 280px;
    }
  }

  &__details {
    width: 100%;
    cursor: pointer;

    @include bp-sm {
      width: 160px;
    }
  }

  &__submit,
  &__is-back-btn {
    @include submit-arrow;
  }

  &__is-back-btn {
    @include back-arrow;

    &::after {
      transform: rotate(180deg);
    }

    &:hover:not(&:disabled) {
      &::after {
        transform: translateX(-5px) rotate(180deg);
      }
    }
  }

  &__processing {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;

    .loader {
      font-size: 16px;
    }

    &::after {
      display: none;
    }
  }

  &__rtl.btn__submit {
    @include back-arrow;

    &::after {
      top: -1px;
    }

    &:hover:not(&:disabled) {
      &::after {
        transform: translateX(-5px) rotate(0);
      }
    }
  }

  &__rtl.btn__is-back-btn {
    @include submit-arrow;

    &:hover:not(&:disabled) {
      &::after {
        transform: translateX(5px) rotate(180deg);
      }
    }
  }

  &__ab-next-available-date {
    box-sizing: content-box;
    padding: 0 16px;
    height: var(--btn-h-sm);
    background-color: var(--extra-color-50) !important;
    border-color: var(--extra-color-50) !important;
    font-family: var(--primary-font) !important;
    letter-spacing: 0;
    font-size: var(--fs-2) !important;

    @include bp-sm {
      width: auto;
      font-size: var(--fs-4) !important;
    }

    &::after {
      display: none;
    }
  }

  &__ab-next-available-date:hover {
    cursor: pointer;
  }

  &__ab-next-available-date-petra {
    height: 22px !important;
  }
}

.fr .btn.btn__submit,
.it .btn.btn__submit,
.fr .btn.btn__is-back-btn,
.it .btn.btn__is-back-btn {
  font-size: 11px;

  @include bp-xs {
    @include p1;
  }

  @include bp-sm {
    @include p5;
  }
}

.fr .btn__is-back-btn,
.it .btn__is-back-btn {
  @include bp-sm {
    top: 0px;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
