.date-picker-new {
  .rc-picker .rc-picker-input input {
    cursor: pointer;
    border: none;
    width: 100%;
    font-size: var(--fs-4);
    line-height: 24px;
    height: 24px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input .rc-picker-input .rc-picker-suffix svg path {
    fill: var(--gray-70);
  }

  .date-picker-new__dropdown {
    z-index: 30000;
    box-shadow: 0 2px 5px rgb(0 0 0 / 15%);
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--gray-pearl-bush);

    .rc-picker-header {
      font-size: var(--fs-4);
      gap: 0;
      padding: 0;
      height: 31px;
      align-items: center;

      button {
        font-size: var(--fs-4);
        display: flex;
        justify-content: center;
        align-items: center;

        svg path {
          stroke: var(--gray-100);
        }
      }

      .rc-picker-header-next-btn,
      .rc-picker-header-prev-btn,
      .rc-picker-header-super-next-btn,
      .rc-picker-header-super-prev-btn {
        position: relative;
        top: -2px;
        height: 24px;
        line-height: 24px;
        font-size: 36px;
      }

      .rc-picker-header-view {
        display: flex;
        justify-content: center;
        gap: 8px;
        font-size: 18px;
        font-weight: 600;

        button {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }

    .rc-picker-content th {
      padding: 0.5em;
      color: #8b9898;
      text-align: center;
      font-weight: normal;
      border: none;
      font-weight: 600;
      font-size: 14px;
    }

    .rc-picker-content td {
      padding: 3px 2px;
    }

    .rc-picker-panel {
      padding: 16px;
      background: white;
      border: none;
    }

    .rc-picker-body {
      margin-top: 16px;
    }

    .rc-picker-cell {
      padding: 0;
      border: none;
    }

    .rc-picker-cell-inner {
      display: flex;
      flex-direction: column;
      width: 34px;
      height: 32px;
      border-radius: 6px;
      font-size: 12px;
      font-weight: 600;
      font-family: var(--primary-font);
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @include bp-md {
        width: 60px;
        height: 54px;
        font-size: 18px;
      }

      &:hover {
        border: 2px solid var(--extra-color-50);
        background-color: var(--success-color-30) !important;
        color: var(--gray-100);
        padding: 2px;
        background-clip: content-box;
      }
    }

    .rc-picker-cell > .rc-picker-cell-inner {
      background-color: var(--success-color-30);
      color: var(--gray-100);
    }

    .rc-picker-cell-disabled > .rc-picker-cell-inner {
      background-color: var(--white);
      color: var(--gray-50);
    }

    .rc-picker-cell-disabled {
      .date-picker-new__price {
        display: none;
      }
    }

    .rc-picker-month-panel .rc-picker-cell-inner {
      width: 80px;

      @include bp-md {
        width: 85px;
      }
    }

    .rc-picker-year-panel .rc-picker-cell-inner {
      width: 80px;

      @include bp-md {
        width: 85px;
      }
    }

    .rc-picker-decade-panel .rc-picker-cell-inner {
      width: 80px;

      @include bp-md {
        font-size: var(--fs-4);
        width: 85px;
      }
    }

    .rc-picker-cell-disabled {
      .rc-picker-cell-inner {
        background-color: var(--white);
        color: var(--gray-50);
        cursor: default;
        pointer-events: none;
      }
    }

    .rc-picker-cell-selected {
      .rc-picker-cell-inner {
        background-color: var(--success-color-30);
        border: 2px solid var(--extra-color-50);
        color: var(--gray-100);
        padding: 2px;
        background-clip: content-box;

        &:hover {
          background-color: var(--success-color-30);
        }
      }
    }

    .rc-picker-cell-today > .rc-picker-cell-inner,
    .rc-picker-cell-inner {
      border-color: var(--color-secondary-50);
    }
  }

  &__hide-outside {
    .rc-picker-cell {
      opacity: 0;
      height: 0px;
      visibility: hidden;
    }

    .rc-picker-cell .next-month {
      display: none;
    }

    .rc-picker-cell-in-view {
      opacity: 1;
      visibility: visible;
      display: table-cell;
    }
  }

  &__hide-today {
    .rc-picker-cell.rc-picker-cell-today .rc-picker-cell-inner {
      border: none;
    }
  }

  &__processing {
    .date-picker-new__dropdown-preloader {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: var(--gray-40-05);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9999;
      cursor: not-allowed;

      svg {
        color: var(--success-color-50);
      }

      .booking-widget--preloader {
        width: 100%;
        justify-content: center;
        right: 0;
      }
    }
  }

  &__price {
    font-family: var(--primary-font);
    font-size: 9px;
    line-height: 9px;
    font-weight: 500;
    color: var(--color-green-3);

    @include bp-md {
      font-size: 14px;
      font-weight: 600;
      margin-top: 6px;
    }
  }
}

.date-picker-new.input-wrapper__error {
  .input .rc-picker-input .rc-picker-suffix svg path {
    fill: var(--error-color-50);
  }
}

.date-picker-new.input-wrapper__disabled {
  .input.rc-picker-disabled {
    cursor: not-allowed;

    .rc-picker-input input {
      cursor: not-allowed;
      background-color: transparent;
    }
  }
}
